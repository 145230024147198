import {BroadcastChannel} from 'broadcast-channel'
import {action, observable} from 'mobx'

import {actions} from '/shared/actions-service'
import {clearPermanentData} from '/shared/permanent-key/clear'
import {never, wait} from '/shared/wait'

import {openGuestInfo} from '/app-shell/guest-links'
import {client} from '/client'
import {becomeGuaranteed} from '/demo-mode/become-guaranteed'
import {logoutApp} from '/env-actions'
import {privacyPolicy} from '/legal-docs/privacy-policy'
import {userAgreement} from '/legal-docs/user-agreement'
import {MainScreen} from '/main-screen'
import {nav} from '/nav-service'
import {fullService} from '/profile-services'
import {captureException} from '/sentry'
import {session} from '/session-service'
import { openProfileFromLink } from '/user-profile'

import {openLogin} from './login-flow'
import {clearPushToken, sendPushToken} from './send-push-token'

const logoutChannel = new BroadcastChannel('logout')

const loginAction = actions.registerAction<{
    from: 'app-shell',
}>({
    name: 'user-become-online',
    act: () => undefined,
})

const registrationAction = actions.registerAction<{
    from: 'app-shell',
}>({
    name: 'user-registered',
    act: () => undefined,
})

export class AppShell {
    @observable.ref
    mainScreen: null | MainScreen = null

    constructor(readonly version: string) {
        logoutChannel.onmessage = () => session.exists && this.logout()

        if (session.exists)
            void this.openApp(false)
        else
            this.openLogin()
    }

    @action
    logout() {
        client.disconnect()
        session.clear()

        void logoutChannel.postMessage(true)
        logoutApp()
        clearPermanentData()

        nav.clear(true)
        void this.openLogin()
    }

    @action
    private openLogin() {
        nav.matcher.add({
            '/new-review-from/:authorId': params => openGuestInfo({id: params.authorId}, 'review'),
            '/new-request-from/:authorId': async params => {
                await this.connect()
                await this.handleLogin()
            },
            '/new-message-from/:authorId': params => openGuestInfo({id: params.authorId}, 'message'),
            '/user/:id': async ({id}) => {
                await this.connect()
                await openProfileFromLink({idOrNick: id})
            },
            '/:nick': async ({nick}) => {
                await this.connect()
                await openProfileFromLink({idOrNick: nick})
            },
            '/chat/:id/fromweb': async () => {
                await this.connect()
                await this.handleLogin()
            },
            '/service/:id': async ({id}) => {
                await this.connect()
                void fullService.act({
                    from: 'link',
                    profileServiceID: id,
                })
            },
            '/docs/privacy-policy': async () => {
                await this.connect()
                void privacyPolicy.act({ from: 'any'})
            },
            '/docs/user-agreement': async () => {
                await this.connect()
                void userAgreement.act({ from: 'any'})
            },
        })

        this.mainScreen?.dispose()
        this.mainScreen = null
        void clearPushToken()
        if (!nav.startRouting(false))
            void this.handleLogin()
    }

    async handleLogin() {
        const s = await openLogin()
        if (!s)
            return
        session.create(s)
        await this.openApp(s.afterRegistration)
        if (s.afterRegistration && s.guaranteed)
            void becomeGuaranteed(false)
    }

    @action
    private async openApp(firstTime: boolean) {
        await wait()
        nav.clear(false)
        if (!await this.connect())
            return

        void loginAction.act({from: 'app-shell'})
        if (firstTime)
            void registrationAction.act({from: 'app-shell'})

        void sendPushToken()
        this.mainScreen = new MainScreen()
        this.mainScreen.start(firstTime)
    }

    @action
    async connect(): Promise<boolean> {
        const err = await client.connect().catch(e => e.message as string)
        if (!err)
            return true

        await wait()
        if (client.badCredentials) {
            this.logout()
            return false
        } else if (client.outdated) {
            //there will be page reload in another module
            return never()
        }

        console.error('Unknown error:')
        console.error(err)
        captureException(Error(err))
        await wait(3000)
        return await this.connect()
    }

    async reloadApp() {
        if (!this.mainScreen)
            throw new Error('Not in app to reload!')
        await this.openApp(false)
    }
}
