import React from 'react'

import {Disposable} from '/shared/dispose'

import {userAgreementEnText} from '/legal-docs/user-agreement/user-agreement-en-text'
import {userAgreementRuText} from '/legal-docs/user-agreement/user-agreement-ru-text'
import {Locale, locales} from '/locales'
import {navActions} from '/nav-actions'
import {Frame, nav} from '/nav-service'

class UserAgreement extends Disposable implements Frame {
    static description = {
        name: 'user-agreement',
    }

    static load = () => {
        return
    }

    get url() {
        return 'docs/user-agreement'
    }

    constructor() {
        super()
    }
}

const texts: Record<Locale, string> = {
    ru: userAgreementRuText,
    en: userAgreementEnText,
}

const userAgreementFrame = nav.registerFrameClass(UserAgreement, {
    header: null,
    bottom: null,
    body: {
        type: 'content',
        block: () => <div dangerouslySetInnerHTML={{ __html: texts[locales.current] }} />,
    },
})

export const userAgreement = navActions.registerNavAction<{
    from: 'any',
}>({
    name: 'open-user-agreement',
    act: () => {
        userAgreementFrame.load({})
    },
})
