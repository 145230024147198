import React from 'react'

import {Disposable} from '/shared/dispose'

import {privacyPolicyENText} from '/legal-docs/privacy-policy/privacy-policy-en-text'
import {privacyPolicyRUText} from '/legal-docs/privacy-policy/privacy-policy-ru-text'
import {Locale, locales} from '/locales'
import {navActions} from '/nav-actions'
import {Frame, nav} from '/nav-service'

class PrivacyPolicy extends Disposable implements Frame {
    static description = {
        name: 'privacy-policy',
    }

    static load = () => {
        return
    }

    get url() {
        return 'docs/privacy-policy'
    }

    constructor() {
        super()
    }
}

const texts: Record<Locale, string> = {
    ru: privacyPolicyRUText,
    en: privacyPolicyENText,
}

const privacyPolicyFrame = nav.registerFrameClass(PrivacyPolicy, {
    header: null,
    bottom: null,
    body: {
        type: 'content',
        block: () => <div dangerouslySetInnerHTML={{ __html: texts[locales.current] }} />,
    },
})

export const privacyPolicy = navActions.registerNavAction<{
    from: 'any',
}>({
    name: 'open-privacy-policy',
    act: () => {
        privacyPolicyFrame.load({})
    },
})
